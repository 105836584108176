<template>
  <div class="thank-you-page">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container fluid>
      <v-card flat>
        <v-card-text>
          <CategoryTitle :category="category" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="handleLogin"
            color="primary"
            large
            depressed
            v-if="showLogin && !isAuthenticated"
          >
            Accedi ora
          </v-btn>
          <v-btn
            large
            depressed
            :to="{ name: 'Home' }"
            color="primary"
            v-if="showGoToHome"
          >
            {{ $t("common.goToHomeBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <category-block
      :target="category"
      :container="false"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>

<script>
import category from "~/mixins/category";
import login from "~/mixins/login";
import CategoryTitle from "@/components/category/CategoryTitle";
export default {
  name: "ResetPasswordSent",
  mixins: [category, login],
  props: {
    showLogin: { type: Boolean, default: false },
    showGoToHome: { type: Boolean, default: false }
  },
  components: { CategoryTitle },
  methods: {
    async handleLogin() {
      if (await this.doLogin("EbsnCategory")) {
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
      // if (await this.needAddress()) {
      //   await this.needTimeslot();

      // }
    }
  },
  created() {
    //this.isLoggedIn("EbsnCategory");
  }
};
</script>

<style lang="scss"></style>
